import { InformationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';

import { bannerContainerClasses } from './classes';

export const ContractRenewedBanner = () => {
  return (
    <div className={clsx(bannerContainerClasses)}>
      <InformationCircleIcon className="h-5 w-5 text-info-600" />
      <p className="font-normal text-black">This contract has been renewed</p>
    </div>
  );
};
