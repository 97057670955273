import type { FC } from 'react';
import { generatePath } from 'react-router-dom';
import type { Column } from 'react-table';
import { Table } from '@components/Table';
import { PillBadge } from '@ContractBuilder/components/PillBadge';
import type { EntityData } from '@ContractBuilder/types';
import { formatDate, sortByDate } from '@root/helpers';
import Nav from '@src/routes';
import { get } from 'lodash-es';

import { CreateRenewalButton } from './CreateRenewalButton';

interface ListRenewalsButtonProps {
  data?: EntityData[];
  isLoading: boolean;
  onCreateRenewal: () => void;
  submission: EntityData;
}

const columns: Array<Column<EntityData>> = [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }) => {
      const value = get(row, 'values.name', '');
      return (
        <p className="w-[132px] max-w-[132px] truncate whitespace-nowrap text-info-500" title={value}>
          {value}
        </p>
      );
    },
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
    Cell: ({ row }) => {
      const value = get(row, 'values.updated_at', get(row, 'values.created_at', ''));
      return <p className="whitespace-nowrap text-info-500">{formatDate(value, 'DD MMM YYYY HH:mm')}</p>;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ row }) => {
      const value = get(row, 'values.status', '');
      return <PillBadge content={value} />;
    },
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ row }) => {
      const value = get(row, 'values.created_at', '');
      return <p className="whitespace-nowrap text-info-500">{formatDate(value, 'DD MMM YYYY')}</p>;
    },
  },
];

export const ListRenewalsButton: FC<ListRenewalsButtonProps> = ({
  data = [],
  isLoading,
  onCreateRenewal,
  submission,
}) => {
  const handleItemClick = (item: EntityData) => {
    const renewalContractPath = generatePath(Nav.SubmissionContract, {
      id: item.id,
    });

    return window.open(renewalContractPath, '_self');
  };

  return (
    <>
      <div className="max-h-[414px] overflow-y-auto shadow-inner">
        <Table<EntityData>
          data={sortByDate(data, 'desc', 'updated_at')}
          emptyRowsLabel="No renewals available"
          columns={columns}
          isLoading={isLoading}
          onRowClick={handleItemClick}
          rowsSkeletonLength={(submission.renewals ?? []).length}
        />
      </div>
      <CreateRenewalButton
        className="align-start w-full items-center rounded-tl-none rounded-tr-none border-b-0 border-l-0 border-r-0 border-t border-info-200"
        isDisabled={isLoading}
        onCreateRenewal={onCreateRenewal}
      />
    </>
  );
};
