import React, { useState } from 'react';
import type { InputTextProps } from '@components/InputText';
import { Show } from '@components/Show';
import { KeyCode } from '@root/@types/types';
import clsx from 'clsx';

import { containerClasses, listHeaderClasses } from './style/classes';
import { SearchBox } from './SearchBox';
import type { SelectMenuItem } from './TreeView';
import { TreeView } from './TreeView';

type SelectMenuListProps = {
  items: SelectMenuItem[];
  isSearchable: boolean;
  filterText?: string;
  label?: string;
  onClose?: Function;
  className?: string;
  disableMotion?: boolean;
  startExpanded?: boolean;
  onItemClick?: (item: SelectMenuItem) => void;
  getAdditionalItemProps?: (item: SelectMenuItem) => Record<string, any>;
  getAdditionalMenuProps?: () => Record<string, any>;
  getAdditionalInputProps?: () => Partial<InputTextProps>;
  disableEntries?: (item: SelectMenuItem) => boolean;
};

export const SelectMenu = (props: SelectMenuListProps) => {
  const {
    isSearchable,
    filterText,
    items,
    disableMotion,
    label,
    className = 'shadow-none max-h-96',
    onItemClick,
    onClose,
    startExpanded,
    getAdditionalItemProps,
    getAdditionalMenuProps,
    getAdditionalInputProps,
    disableEntries,
  } = props;

  const [searchText, setSearchText] = useState('');

  const wrapperRef = React.createRef<HTMLDivElement>();

  const containerRef = React.createRef<HTMLDivElement>();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === KeyCode.Esc) {
      onClose?.();
      return true;
    }

    return false;
  };

  return (
    <div
      className={containerClasses({ className })}
      onKeyDown={handleKeyDown}
      ref={wrapperRef}
      tabIndex={0}
      role="group"
    >
      <Show when={!!label}>
        <div className={clsx(listHeaderClasses)}>{label}</div>
      </Show>
      <Show when={isSearchable}>
        <SearchBox {...getAdditionalInputProps?.()} onChange={setSearchText} searchText={searchText} />
      </Show>
      <div className="grow overflow-auto" ref={containerRef} {...getAdditionalMenuProps?.()}>
        <div>
          <TreeView
            items={items}
            searchText={isSearchable ? searchText : filterText}
            onItemClick={onItemClick}
            disableMotion={disableMotion}
            startExpanded={startExpanded}
            getAdditionalItemProps={getAdditionalItemProps}
            disableEntries={disableEntries || (() => false)}
          />
        </div>
      </div>
    </div>
  );
};
