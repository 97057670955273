import React from 'react';
import { EndorsementsCountBadge } from '@pages/User/components';
import { isEmptyValue } from '@root/helpers';
import type { CellContext, ColumnHelper } from '@tanstack/react-table';
import { get } from 'lodash-es';

enum ShowRenewalsOptions {
  Show = 'SHOW',
  Hide = 'HIDE',
}

const renewalsFilterOptions = [
  { value: ShowRenewalsOptions.Show, label: 'Show only renewals' },
  { value: ShowRenewalsOptions.Hide, label: 'Hide renewals' },
];

export const renewalsAccessor = <T extends { renewals?: string[]; renewalParentId?: string }>(
  columnHelper: ColumnHelper<T>,
) =>
  columnHelper.accessor((entity) => entity.renewals ?? [], {
    id: 'renewals',
    header: 'RNEW',
    cell: (cellContext: CellContext<T, string[]>) => (
      <EndorsementsCountBadge count={get(cellContext, 'row.original.renewals', []).length} />
    ),
    meta: {
      filter: {
        label: 'Renewals',
        placeholder: 'Show renewals',
        isSingleSelection: true,
        optionsMapping: renewalsFilterOptions,
        order: Number.MAX_SAFE_INTEGER,
      },
    },
    sortingFn: (a, b) => {
      const aRenewals = get(a, 'original.renewals', []);
      const bRenewals = get(b, 'original.renewals', []);

      // Sort by the number of elements in each array
      return aRenewals.length - bRenewals.length > 0 ? 1 : -1;
    },
    sortUndefined: 'last',
    filterFn: (row, _, selected) => {
      if (selected === ShowRenewalsOptions.Show) {
        return row.original.renewalParentId !== undefined;
      }

      if (selected === ShowRenewalsOptions.Hide) {
        return isEmptyValue(row.original.renewalParentId);
      }

      return true;
    },
  });
