import type { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/outline';
import Nav from '@src/routes';
import clsx from 'clsx';

import { bannerContainerClasses } from './classes';

interface ViewOriginalContractBannerProps {
  name: string;
  parentId: string;
}

export const ViewOriginalContractBanner: FC<ViewOriginalContractBannerProps> = ({ name, parentId }) => {
  return (
    <div className={clsx(bannerContainerClasses)}>
      <InformationCircleIcon className="h-5 w-5 text-info-600" />
      <p className="font-normal text-black">
        You are viewing renewal: <strong className="mr-2">{name}</strong> &rarr;
        <Link
          className="ml-4 cursor-pointer font-medium underline transition-colors hover:opacity-75"
          to={generatePath(Nav.SubmissionContract, { id: parentId })}
        >
          View original contract
        </Link>
      </p>
    </div>
  );
};
