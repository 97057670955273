import type { PublishingOptionType } from '@ContractBuilder/types';
import StatusBadge from '@pages/User/components/StatusBadge';
import type { BrandingStatus } from '@root/@types/types';
import { TEMPLATE_STATUSES } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';

import type { Branding } from './types';

const columnHelper = createColumnHelper<Branding>();

export const INITIAL_BRANDING_COLS = [
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Branding name',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      filter: {
        placeholder: 'All branding',
      },
    },
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: 'Status',
    filterFn: 'arrIncludesSome',
    cell: (cellContext: CellContext<Branding, string>) => <StatusBadge status={cellContext.getValue()} />,
    meta: {
      filter: {
        placeholder: 'All statuses',
        selected: TEMPLATE_STATUSES.filter((status) => status !== 'ARCHIVED'),
      },
      dataCellClassName: 'align-top !p-[1px]',
      headingLinkClassName: '!pl-0',
    },
  }),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    header: 'Created at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<Branding, string>) =>
      formatDate(cellContext.row.original.created_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.accessor('updated_at', {
    id: 'updated_at',
    header: 'Updated at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<Branding, string>) =>
      formatDate(cellContext.row.original.updated_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.display({
    id: 'view',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<Branding, unknown>) => (
      <a
        className="font-light text-primary-600 hover:text-primary-900"
        target="_self"
        href={`/branding/${cellContext.row.getValue('id')}`}
        rel="noreferrer"
      >
        View
      </a>
    ),
    meta: {
      dataCellClassName:
        'relative w-20 truncate whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
      headingCellClassName: 'relative w-20 py-3.5 pl-3 pr-4 sm:pr-6',
    },
  }),
];

export const brandingPublishingOptions: PublishingOptionType[] = [
  {
    id: 'DRAFT',
    title: 'Draft',
    description: 'This branding is a work in progress and unavailable to use on new submissions.',
  },
  {
    id: 'PUBLISHED',
    title: 'Published',
    description: 'Make this branding available to use when creating new submissions.',
  },
  {
    id: 'ARCHIVED',
    title: 'Archived',
    description: 'Prevent this branding from being used when creating new submissions.',
  },
];

export const ALLOWED_STATUSES: Record<BrandingStatus, BrandingStatus[]> = {
  DRAFT: ['ARCHIVED', 'PUBLISHED', 'DRAFT'],
  PUBLISHED: ['ARCHIVED', 'PUBLISHED'],
  ARCHIVED: ['ARCHIVED', 'PUBLISHED'],
};

export const MAX_HEADER_HEIGHT = 136; //px min 120px
export const MAX_FOOTER_HEIGHT = 145; //px min 75px

export const IMG_VALID_TIME = 300 * 1000;
