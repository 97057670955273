import type { FC } from 'react';
import { useCallback } from 'react';
import { Show } from '@components/Show';
import { Spinner } from '@components/Spinner';
import { InfiniteLoader } from '@ContractBuilder/modules/infinite-loader';
import type { DynamoDBLibraryBlock } from '@root/@types/database';
import type { Table } from '@tanstack/react-table';
import clsx from 'clsx';

import type { LibraryBlockType, LibraryBlockTypeForListPage } from '../../../types';

import type { BlocksLibrarySearchInputProps } from './BlocksLibrarySearchInput';
import { BlocksLibrarySearchInput } from './BlocksLibrarySearchInput';
import { BlocksNotFound } from './BlocksNotFound';
import { LibraryBlock } from './LibraryBlock';

export interface BlockLibraryListProps extends Omit<BlocksLibrarySearchInputProps, 'documentType'> {
  blocks: LibraryBlockType[];
  className?: string;
  total: number;
  onInsert: (blockLibraryId: string, block: DynamoDBLibraryBlock | null) => Promise<void>;
  onLoadMoreItems: (previouslyLoadedItems: LibraryBlockType[]) => void;
  isFetching: boolean;
  table: Table<LibraryBlockTypeForListPage>;
}

export const BlocksLibraryList: FC<BlockLibraryListProps> = ({
  blocks,
  className,
  total,
  onInsert,
  onLoadMoreItems,
  ...blocksLibrarySearchInputProps
}) => {
  const rows = blocksLibrarySearchInputProps.table.getRowModel().rows;
  const renderBlock = useCallback(
    (block: LibraryBlockType) => {
      const tableRow = rows.find(({ original }) => original.id === block.id);
      if (!tableRow) {
        return null;
      }
      return (
        <LibraryBlock
          key={block.id}
          block={block}
          onBlockInsert={() => onInsert(block.id, block)}
          tableRow={tableRow}
        />
      );
    },
    [onInsert, rows],
  );

  const hasBlocks = blocks.length > 0;

  return (
    <div className="flex flex-col gap-2 !text-xs text-info-700">
      <BlocksLibrarySearchInput {...blocksLibrarySearchInputProps} />
      <div className={clsx('max-h-[324px] overflow-y-auto', className)}>
        <Show when={hasBlocks}>
          <InfiniteLoader data={blocks} total={total} renderItem={renderBlock} onLoadMoreItems={onLoadMoreItems} />
          <Show when={blocksLibrarySearchInputProps.isFetching}>
            <div className="ml-auto flex cursor-pointer items-center justify-center rounded border border-info-200 bg-info-200 py-5 transition">
              <Spinner />
            </div>
          </Show>
        </Show>
        <Show when={!hasBlocks}>
          <BlocksNotFound />
        </Show>
      </div>
    </div>
  );
};
