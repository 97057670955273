import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { isNonNullish } from '@root/helpers';
import clsx from 'clsx';

import { pageNavigationButtonClasses } from './classes';
import { PaginationItems } from './PaginationItems';

export interface PaginationProps {
  onPageChange: (page: number) => void;
  page?: number;
  pageSize: number;
  totalResults: number;
}

export const Pagination: FC<PaginationProps> = ({ pageSize, page, totalResults, onPageChange }) => {
  const [_currentPageIndex, setCurrentPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const isControlled = isNonNullish(page);
  const currentPageIndex = isControlled ? Math.max(0, page - 1) : _currentPageIndex;

  useEffect(() => {
    setCurrentPageIndex(0);
    setTotalPages(Math.ceil(totalResults / pageSize));
  }, [pageSize, totalResults]);

  const changePage = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
    onPageChange(newPageIndex);
  };

  const handleNextPageClick = () => {
    if (currentPageIndex + 1 < totalPages) {
      return changePage(currentPageIndex + 1);
    }
  };

  const handlePreviousPageClick = () => {
    if (currentPageIndex > 0) {
      return changePage(currentPageIndex - 1);
    }
  };

  return (
    <div className="sticky bottom-0 z-20 flex items-center justify-between border-t border-info-200 bg-white px-4 py-3 pl-3">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-info-700">
            {totalResults > 0 ? (
              <>
                Showing <span className="font-medium">{1 + currentPageIndex * pageSize}</span> to{' '}
                <span className="font-medium">
                  {(currentPageIndex + 1) * pageSize > totalResults ? totalResults : (currentPageIndex + 1) * pageSize}
                </span>{' '}
                of <span className="font-medium">{totalResults}</span> results
              </>
            ) : (
              <span className="font-medium">0 results</span>
            )}
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={handlePreviousPageClick}
              className={clsx(pageNavigationButtonClasses({ isPrevious: true }))}
              type="button"
            >
              <span className="sr-only">Previous page</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <PaginationItems currentPageIndex={currentPageIndex} onPageChange={changePage} totalPages={totalPages} />
            <button
              onClick={handleNextPageClick}
              className={clsx(pageNavigationButtonClasses({ isNext: true }))}
              type="button"
            >
              <span className="sr-only">Next page</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
