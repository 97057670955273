import Button from '@components/Button';
import { PlusSmIcon } from '@heroicons/react/outline';
import { MODALS } from '@src/constants';
import { useModal } from '@src/hooks';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface CreateEndorsementButtonProps {
  className?: string;
  isDisabled?: boolean;
  onCreateEndorsement: () => void;
}

export const CreateEndorsementButton = ({
  className,
  isDisabled,
  onCreateEndorsement,
}: CreateEndorsementButtonProps) => {
  const { showModal } = useModal();

  const handleButtonClick = () => {
    showModal(MODALS.CREATE_ENDORSEMENT, {
      refetch: onCreateEndorsement,
    });
  };

  return (
    <Button
      className={twMerge(clsx('flex h-9 justify-start text-info-600', className))}
      isDisabled={isDisabled}
      onClick={handleButtonClick}
      size="sm"
    >
      <PlusSmIcon className="h-5 w-5 text-info-600" />
      Create new endorsement
    </Button>
  );
};
