import type { FC } from 'react';
import React, { useRef } from 'react';
import { CloseIconButton } from '@components/atoms/ClearInputButton';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { DEFAULT_CREATOR_PADDING } from '@ContractBuilder/modules/block-creator/constants';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { mdiChevronDown, mdiFilter } from '@mdi/js';
import { GlobalSearch } from '@pages/User/components';
import { useEffectOnce } from '@src/hooks';
import { useLanguage } from '@src/language';
import type { ColumnFilter, Table } from '@tanstack/react-table';
import clsx from 'clsx';

import { SearchFiltersDropdown } from './SearchFiltersDropdown';

export interface BlocksLibrarySearchInputProps {
  filters: ColumnFilter[];
  onSearchReset: () => void;
  onSearch: (searchInput: string) => void;
  searchText?: string;
  isFetching: boolean;
  table: Table<LibraryBlockTypeForListPage>;
}

const filterPillClasses = ['flex items-center gap-1 rounded-xl border border-info-400 bg-white px-1.5 py-0.5'];
const filterPillCloseIconClasses = ['mt-0.5 cursor-pointer text-info-500 transition duration-300 hover:text-info-400'];

export const BlocksLibrarySearchInput: FC<BlocksLibrarySearchInputProps> = ({
  filters,
  onSearch,
  onSearchReset,
  isFetching,
  table,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });

  const focusSearch = () => {
    if (ref.current) {
      ref.current?.focus();
    }
  };

  useEffectOnce(() => {
    focusSearch();
  });

  const hasFilters =
    filters.find(({ id, value }) => id !== 'deleted_at' && ((value as string[])?.length ?? 0) > 0) !== undefined;

  const handleIndividualBlockTypeFilterClear = (valueToBeCleared: string) => {
    const currentSelectedFilterValues = filters?.find(({ id }) => id === 'type')?.value ?? [];
    const blockTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === 'type');
    blockTypeColumn?.setFilterValue(
      (currentSelectedFilterValues as string[]).filter((value: string) => value !== valueToBeCleared),
    );
    if (valueToBeCleared === 'Clause') {
      const clauseTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === 'clause_type');
      clauseTypeColumn?.setFilterValue([]);
    }
  };

  const handleIndividualClauseTypeFilterClear = (valueToBeCleared: string) => {
    const currentSelectedFilterValues = filters?.find(({ id }) => id === 'clause_type')?.value ?? [];
    const clauseTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === 'clause_type');
    clauseTypeColumn?.setFilterValue(
      (currentSelectedFilterValues as string[]).filter((value: string) => value !== valueToBeCleared),
    );
  };

  const handleSearchClick = (searchTerm: string) => onSearch(searchTerm);

  return (
    <div className={clsx(DEFAULT_CREATOR_PADDING, 'flex flex-col gap-4')}>
      <div className="flex w-full place-items-center gap-2">
        <div className={'relative flex h-10 w-full items-center overflow-clip rounded-md'}>
          <GlobalSearch
            inputClassName="appearance-none rounded-md border border-info-300 py-2 text-sm text-info-700 shadow-sm focus:border-primary-500 focus:ring-primary-500 h-10 rounded-r-none bg-white px-10 pl-10"
            wrapperClassName="grow"
            entitiesLabel={`by name or keyword`}
            onSearchClick={handleSearchClick}
          />
          <div className="relative">
            <SearchFiltersDropdown
              filters={filters}
              table={table}
              renderTrigger={({ onClick }) => (
                <button
                  className="flex h-10 w-32 items-center justify-between border border-l-0 border-info-200 bg-info-50 p-2 py-3 hover:bg-opacity-75"
                  onClick={onClick}
                >
                  <div data-testid="filter-btn" className="ml-auto flex items-center gap-1">
                    <IconMdi path={mdiFilter} className="text-info-400" />
                    <span className="text-sm font-medium leading-5">Filters</span>
                  </div>
                  <IconMdi className="ml-4" path={mdiChevronDown} />
                </button>
              )}
              isFetching={isFetching}
            />
          </div>
        </div>
      </div>
      <Show when={hasFilters}>
        <div className="ml-1 flex items-start gap-3 border-b border-gray-200 pb-5 text-sm font-medium text-info-900 ">
          FILTERS:
          <div className="flex flex-wrap items-center gap-4">
            {(filters?.find((i) => i.id === 'type')?.value as string[])?.map((selectedValue) => (
              <button
                className={clsx(filterPillClasses)}
                key={`filter-${selectedValue}`}
                onClick={() => handleIndividualBlockTypeFilterClear(selectedValue)}
              >
                {getContent(selectedValue)}
                <CloseIconButton size={0.5} onClick={onSearchReset} className={clsx(filterPillCloseIconClasses)} />
              </button>
            ))}
            {(filters?.find((i) => i.id === 'clause_type')?.value as string[])?.map((selectedValue) => (
              <button
                className={clsx(filterPillClasses)}
                key={`filter-${selectedValue}`}
                onClick={() => handleIndividualClauseTypeFilterClear(selectedValue)}
              >
                {selectedValue}
                <CloseIconButton size={0.5} onClick={onSearchReset} className={clsx(filterPillCloseIconClasses)} />
              </button>
            ))}
          </div>
        </div>
      </Show>
    </div>
  );
};
