import React from 'react';
import { TABLE_VIEW_HEIGHT } from '@pages/User/constants';
import { getTagsTableColumns } from '@pages/User/helpers/columns/getTagsTableColumns';
import type { Action, TagShort } from '@root/@types/types';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useModal } from '@src/hooks';
import { fetchTags } from '@src/queries';

import { MODALS } from '../../constants';

import { ReactTable } from './components';
import { useDataQuery } from './hooks';

export const Tags: React.FC = () => {
  const { showModal } = useModal();
  const { data, status, setShouldFetchData } = useDataQuery('tags', [], fetchTags);

  const handleEditTagClick = (tag: TagShort) => () =>
    showModal(MODALS.ADMIN_TAG, { tag, setShouldFetchData, isAdd: false });

  const columns = getTagsTableColumns({ handleEditTagClick });

  const actions: Action[] = [
    {
      key: 'add_tag',
      label: 'New Tag',
      onClick: () => {
        showModal(MODALS.ADMIN_TAG, { setShouldFetchData, isAdd: true });
      },
    },
  ];

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        id: false,
      }}
      enableColumnFilters={false}
      entitiesLabel="tags"
      filteredData={data}
      initialSorting={[{ id: 'created_at', desc: true }]}
      status={status}
      tableClasses={TABLE_VIEW_HEIGHT}
    />
  );
};

export const UserTagsPage = withMenuLayout(Tags);
