import type { FC } from 'react';
import Stick from 'react-stick/lib/Stick';
import { DATAPOINT, DERIVED_CONTENT, PPTR_TEMPLATE } from '@components/SelectMenu/constants';
import { handleDatapointInsert } from '@components/SelectMenu/handleDatapointInsert';
import { SelectMenu } from '@components/SelectMenu/SelectMenu';
import { useToggle } from '@hooks/useToggle';
import { InsertIcon } from '@WysiwygEditor/components/InsertMenu/components/InsertIcon';

import { brandingDatapoints } from '../branding-datapoints';

interface SelectMenuContainerProps {
  editor: any;
}

export const BrandingInsertMenu: FC<SelectMenuContainerProps> = ({ editor }) => {
  const [isOpen, _onOpen, onClose, onToggle] = useToggle(false);

  const onItemClick = (item: any) => {
    if (item.type === DATAPOINT || item.type === DERIVED_CONTENT || item.type === PPTR_TEMPLATE)
      return handleDatapointInsert(editor, onClose, item);
    return undefined;
  };

  return (
    <Stick
      style={{
        node: {
          zIndex: 1200,
        },
      }}
      node={
        isOpen ? (
          <SelectMenu
            isSearchable={true}
            items={brandingDatapoints}
            label="Data points"
            onClose={onClose}
            onItemClick={onItemClick}
            className="min-h-[500px] shadow-none"
          />
        ) : null
      }
      position="bottom left"
      align="top left"
      onClickOutside={onClose}
    >
      <button
        data-testid="insert-datapoint-button"
        onClick={onToggle}
        className="flex cursor-pointer items-center gap-x-2 rounded-md px-1 py-0.5 text-sm text-info-800 transition-colors hover:bg-primary-50"
        disabled={!editor}
      >
        <InsertIcon />
        Insert
      </button>
    </Stick>
  );
};
