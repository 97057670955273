import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import type { LibraryBlockType } from '@ContractBuilder/types';
import { useBlocksLibrary } from '@hooks/useBlocksLibrary';
import { getBlocksTableColumns } from '@pages/User/helpers/columns/getBlocksTableColumns';
import { fuzzyFilter } from '@pages/User/helpers/filters/fuzzyFilter';
import type { DynamoDBLibraryBlock } from '@root/@types/database';
import {
  type ColumnFiltersState,
  type ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { useFilteredBlocks } from '../hooks/use-filtered-blocks';
import { BlockLibraryListSuccess, BlocksLibraryLoading } from '../views';

interface BlockLibraryCreatorListControllerProps {
  onInsertBlock: (blockLibraryId: string, block: DynamoDBLibraryBlock | null) => Promise<void>;
}

const initialColumnFilters = [{ id: 'deleted_at', value: false }];
const initialPagination = {
  pageIndex: 0,
  pageSize: 20,
};

export const BlockLibraryCreatorListController: FC<BlockLibraryCreatorListControllerProps> = ({ onInsertBlock }) => {
  const [previouslyLoadedItems, setPreviouslyLoadedItems] = useState<LibraryBlockType[]>([]);
  const { data: blocks = [], isFetching } = useBlocksLibrary();

  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [pagination, setPagination] = useState(initialPagination);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'created_at', desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialColumnFilters);
  const [globalFilter, setGlobalFilter] = useState('');

  const columns = getBlocksTableColumns();

  const table = useReactTable({
    columns,
    data: blocks,
    initialState: {
      columnVisibility: {
        id: false,
      },
    },
    state: {
      columnFilters,
      expanded,
      globalFilter,
      pagination,
      sorting,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    enableColumnFilters: true,
    sortDescFirst: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    globalFilterFn: 'fuzzy',
    getColumnCanGlobalFilter: (column) => !['created_at', 'updated_at'].includes(column.id),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 1,
  });

  const filteredBlocks = useFilteredBlocks(
    table.getRowModel().rows.map(({ original }) => original),
    globalFilter,
  );

  const resetPagination = () => {
    setPreviouslyLoadedItems([]);
    setPagination(initialPagination);
  };

  const onSearchApply = (searchInput: string) => {
    resetPagination();
    setGlobalFilter(searchInput);
  };

  const onSearchReset = () => {
    resetPagination();
    setGlobalFilter('');
  };

  const handleLoadMoreItems = (previouslyLoadedItems: LibraryBlockType[]) => {
    setPreviouslyLoadedItems(previouslyLoadedItems);
    setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 });
  };

  useEffect(() => {
    resetPagination();
  }, [columnFilters]);

  if (isFetching && !previouslyLoadedItems?.length) {
    return <BlocksLibraryLoading />;
  }

  const loadedBlocks = [...previouslyLoadedItems, ...filteredBlocks];
  const loadedBlocksCount = loadedBlocks.length ?? 0;

  return (
    <BlockLibraryListSuccess
      blocks={loadedBlocks}
      count={loadedBlocksCount}
      filters={columnFilters}
      onInsert={onInsertBlock}
      onSearch={onSearchApply}
      onSearchReset={onSearchReset}
      searchText={globalFilter}
      total={table.getRowCount()}
      onLoadMoreItems={handleLoadMoreItems}
      isFetching={isFetching}
      table={table}
    />
  );
};
