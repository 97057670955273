import { useEffect } from 'react';
import MODALS from '@constants/modals';
import { useModal } from '@hooks/useModal';
import { ReactTable } from '@pages/User/components';
import { TABLE_VIEW_HEIGHT } from '@pages/User/constants';
import { useDataQuery } from '@pages/User/hooks';
import { fetchBrandings } from '@queries/fetchBrandings';
import type { BrandingType } from '@root/@types/types';
import { TEMPLATE_STATUSES } from '@root/@types/types';

import { INITIAL_BRANDING_COLS } from '../constants';
import { useBrandingStore } from '../store/branding.store';
import { pickValidBrandingTemplates } from '../utils/get-branding-templates';

import { useBrandingTemplateStore } from './branding-template-selector/branding-templates.store';

export const BrandingPage = () => {
  const { reset } = useBrandingStore((s) => s);
  const { reset: resetTemplates, setBrandingTemplates } = useBrandingTemplateStore((s) => s);

  const { showModal } = useModal();

  const { data, status, setShouldFetchData } = useDataQuery<BrandingType>('brandings', [], fetchBrandings);

  useEffect(() => {
    reset();
    // eslint-disable-next-line -- just reset the store
  }, []);

  const openBrandingCreator = () => {
    resetTemplates();
    setBrandingTemplates(pickValidBrandingTemplates(data));
    showModal(MODALS.CREATE_BRANDING, { refetch: () => setShouldFetchData(true) });
  };

  return (
    <ReactTable
      actions={[
        {
          key: 'create',
          label: 'New Branding',
          onClick: openBrandingCreator,
        },
      ]}
      columns={INITIAL_BRANDING_COLS}
      columnVisibility={{
        id: false,
      }}
      entitiesLabel="branding"
      filteredData={data}
      initialFilters={[{ id: 'status', value: TEMPLATE_STATUSES.filter((status) => status !== 'ARCHIVED') }]}
      initialSorting={[{ id: 'created_at', desc: true }]}
      status={status}
      tableClasses={TABLE_VIEW_HEIGHT}
    />
  );
};
