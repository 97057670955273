import { cva } from 'class-variance-authority';

export const layoutChildrenClasses = cva(['flex', 'h-screen'], {
  variants: {
    padding: { true: ['py-6'], false: [] },
  },
  defaultVariants: {
    padding: true,
  },
});
