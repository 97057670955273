import type { FC } from 'react';
import { useEntityStore } from '@ContractBuilder/store';
import type { EntityData } from '@ContractBuilder/types';
import { useRenewalsData } from '@src/hooks';

import { ListRenewalsButton } from '../views/ListRenewalsButton';

interface RenewalsButtonControllerProps {
  submission: EntityData;
}

export const RenewalsButtonController: FC<RenewalsButtonControllerProps> = ({ submission }) => {
  const reloadSubmission = useEntityStore(({ reloadSubmission }) => reloadSubmission);
  const { data, isFetching } = useRenewalsData({
    refetchOnWindowFocus: false,
  });

  return (
    <ListRenewalsButton data={data} isLoading={isFetching} onCreateRenewal={reloadSubmission} submission={submission} />
  );
};
