import { DATAPOINT, PPTR_TEMPLATE } from '@components/SelectMenu/constants';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import type { BrandingDatapointId } from '@root/@types/types';

const isPPL = import.meta.env?.VITE_CLIENT === 'ct-ppl';

export const brandingDatapoints: SelectMenuItem<BrandingDatapointId>[] = [
  {
    label: 'Submission Metadata',
    id: 'metadata',
    order: 0,
    type: 'group',

    children: [
      { label: 'Submission ID', id: 'ref', order: 0, type: DATAPOINT },
      { label: 'Submission Name', id: 'name', order: 1, type: DATAPOINT },
      ...(isPPL ? [] : [{ label: 'Submission Team', id: 'teams' as const, order: 2, type: DATAPOINT }]),
      { label: 'Submission Class of Business', id: 'class_of_business', order: 3, type: DATAPOINT },
      { label: 'Date Created At', id: 'date_created_at', order: 4, type: DATAPOINT },
      { label: 'Time Created At', id: 'time_created_at', order: 5, type: DATAPOINT },
      { label: 'Date Updated At', id: 'date_updated_at', order: 6, type: DATAPOINT },
      { label: 'Time Updated At', id: 'time_updated_at', order: 7, type: DATAPOINT },
      { label: 'Created By Name', id: 'created_by.name', order: 8, type: DATAPOINT },
    ],
  },
  {
    label: 'Submission Datapoints',
    id: 'datapoints',
    order: 1,
    type: 'group',
    children: [
      {
        label: 'Unique Market Reference',
        id: 'data_items.risk_details.unique_market_reference.unique_market_reference',
        order: 0,
        type: DATAPOINT,
      },
      {
        label: 'Placing Broker Unique Identification',
        id: 'data_items.risk_details.unique_market_reference.placing_broker_unique_identifier',
        order: 1,
        type: DATAPOINT,
      },
    ],
  },
  {
    label: 'Page Counter',
    id: 'page_counter',
    order: 2,
    type: 'group',
    children: [
      { label: 'Current Page', id: 'pageNumber', order: 0, type: PPTR_TEMPLATE },
      { label: 'Page Count', id: 'totalPages', order: 1, type: PPTR_TEMPLATE },
    ],
  },
];
